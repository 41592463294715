@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
/* ==========================================================================
   Responsive images and box padding
   ========================================================================== */

img,
object {
  display: block;
  border: 0;
  max-width: 100%;
  height: auto;
}
* {
  -moz-box-sizing: border-box;
  -webkit-kit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

body {
  font-size: 100%;
  background: #fefefe;
  font-family: NunitoSans, Arial, serif, Helvetica, sans-serif;
}

.container {
  width: 80%;
  margin: 60px auto;
}

/* Hide arrow input type number - start */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: NunitoSans;
  src: url('./assets/fonts/NunitoSans-Regular.ttf');
}

.title-admin {
  color: #B1C2D8 !important;
  font-weight: normal !important;
  font-size: 1.8rem !important;
  margin-top: 1.5rem !important;
  margin-bottom: .3rem !important;
}

/* Hide arrow input type number - end */